/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { MessageDetailsFragment } from "./fragments.generated";
import { MessageDetailsWithChatMinFragment } from "./fragments.generated";
import { ChatNoteMentionFragment } from "./fragments.generated";
import { MessageDetailsFragmentDoc } from "./fragments.generated";
import { MessageDetailsWithChatMinFragmentDoc } from "./fragments.generated";
import { ChatNoteMentionFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type DeleteMessageMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  messageUuid: Types.Scalars["ID"];
}>;

export type DeleteMessageMutation = {
  __typename: "RootMutationType";
  deleteMessage: ({ __typename: "Message" } & MessageDetailsFragment) | null;
};

export type MarkMessagesAsHandledMutationVariables = Types.Exact<{
  handled: Types.Scalars["Boolean"];
  messagesUuids: Array<Types.InputMaybe<Types.Scalars["ID"]>> | Types.InputMaybe<Types.Scalars["ID"]>;
  numberUuid: Types.Scalars["ID"];
}>;

export type MarkMessagesAsHandledMutation = {
  __typename: "RootMutationType";
  markMessagesAsHandled: Array<{ __typename: "Message"; uuid: string | null; isHandled: boolean | null } | null> | null;
};

export type AssignMessageNumberTagMutationVariables = Types.Exact<{
  messageUuid: Types.Scalars["ID"];
  numberTagUuid: Types.Scalars["ID"];
}>;

export type AssignMessageNumberTagMutation = {
  __typename: "RootMutationType";
  assignMessageNumberTag: ({ __typename: "Message" } & MessageDetailsFragment) | null;
};

export type RemoveMessageNumberTagMutationVariables = Types.Exact<{
  messageUuid: Types.Scalars["ID"];
  numberTagUuid: Types.Scalars["ID"];
}>;

export type RemoveMessageNumberTagMutation = {
  __typename: "RootMutationType";
  removeMessageNumberTag: ({ __typename: "Message" } & MessageDetailsFragment) | null;
};

export type SubmitTextMessageMutationVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
  content: Types.Scalars["String"];
  faqUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
  optInUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
  attachmentId?: Types.InputMaybe<Types.Scalars["ID"]>;
  isTranslationEnabled?: Types.InputMaybe<Types.Scalars["Boolean"]>;
  translatedContent?: Types.InputMaybe<Types.Scalars["String"]>;
  originalLanguageCode?: Types.InputMaybe<Types.Scalars["String"]>;
  translatedLanguageCode?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type SubmitTextMessageMutation = {
  __typename: "RootMutationType";
  submitTextMessage: ({ __typename: "Message" } & MessageDetailsFragment) | null;
};

export type CreateTemplatedMessageMutationVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
  template: Types.TemplatedMessageTemplateIo;
  attachmentId?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type CreateTemplatedMessageMutation = {
  __typename: "RootMutationType";
  createTemplatedMessage: ({ __typename: "Message" } & MessageDetailsFragment) | null;
};

export type ScheduleReminderMutationVariables = Types.Exact<{
  accountUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
  segmentUuid?: Types.InputMaybe<Types.Scalars["ID"]>;
  query: Types.Scalars["String"];
  attachmentId?: Types.InputMaybe<Types.Scalars["ID"]>;
  scheduledAt?: Types.InputMaybe<Types.Scalars["DateTime"]>;
  name?: Types.InputMaybe<Types.Scalars["String"]>;
  templateParams: Types.TemplatedMessageTemplateIo;
}>;

export type ScheduleReminderMutation = {
  __typename: "RootMutationType";
  scheduleReminder: { __typename: "Reminder"; uuid: string } | null;
};

export type SearchMessagesMutationVariables = Types.Exact<{
  numberUuid: Types.Scalars["ID"];
  query: Types.SearchQueryIo;
  page?: Types.InputMaybe<Types.Scalars["Int"]>;
  pageSize?: Types.InputMaybe<Types.Scalars["Int"]>;
  sort?: Types.InputMaybe<Types.SortType>;
}>;

export type SearchMessagesMutation = {
  __typename: "RootMutationType";
  searchMessages: {
    __typename: "MessageSearchResults";
    count: number | null;
    results: Array<({ __typename: "Message" } & MessageDetailsWithChatMinFragment) | null> | null;
  } | null;
};

export type CreateChatNoteMutationVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
  content: Types.Scalars["String"];
  mentions: Array<Types.ChatNoteMentionIo> | Types.ChatNoteMentionIo;
}>;

export type CreateChatNoteMutation = {
  __typename: "RootMutationType";
  createChatNote: ({ __typename: "Message" } & MessageDetailsFragment) | null;
};

export type DeleteChatNoteMutationVariables = Types.Exact<{
  chatNoteUuid: Types.Scalars["ID"];
}>;

export type DeleteChatNoteMutation = {
  __typename: "RootMutationType";
  deleteChatNote: { __typename: "Message"; uuid: string | null } | null;
};

export type ToggleMentionIsClearedMutationVariables = Types.Exact<{
  isCleared: Types.Scalars["Boolean"];
  mentionUuid: Types.Scalars["ID"];
}>;

export type ToggleMentionIsClearedMutation = {
  __typename: "RootMutationType";
  toggleMentionIsCleared: ({ __typename: "ChatNoteMention" } & ChatNoteMentionFragment) | null;
};

export type SendReactionToMessageMutationVariables = Types.Exact<{
  emoji: Types.Scalars["String"];
  messageUuid: Types.Scalars["ID"];
  numberUuid: Types.Scalars["ID"];
}>;

export type SendReactionToMessageMutation = {
  __typename: "RootMutationType";
  sendReactionToMessage: ({ __typename: "Message" } & MessageDetailsFragment) | null;
};

export type PreviewTranslationMutationVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
  content: Types.Scalars["String"];
  originalLanguageCode: Types.Scalars["String"];
  translatedLanguageCode: Types.Scalars["String"];
}>;

export type PreviewTranslationMutation = {
  __typename: "RootMutationType";
  previewTranslation: { __typename: "TranslationPreview"; translatedContent: string | null } | null;
};

export type TranslateMessagesMutationVariables = Types.Exact<{
  chatUuid: Types.Scalars["ID"];
  messageUuids: Array<Types.InputMaybe<Types.Scalars["ID"]>> | Types.InputMaybe<Types.Scalars["ID"]>;
  accountLanguageCode: Types.Scalars["String"];
  contactLanguageCode: Types.Scalars["String"];
}>;

export type TranslateMessagesMutation = {
  __typename: "RootMutationType";
  translateMessages: { __typename: "TranslateMessagesResult"; failedMessagesUuids: Array<string | null> } | null;
};

export const DeleteMessageDocument = gql`
  mutation deleteMessage($numberUuid: ID!, $messageUuid: ID!) {
    deleteMessage(messageUuid: $messageUuid, numberUuid: $numberUuid) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      messageUuid: // value for 'messageUuid'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
}
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const MarkMessagesAsHandledDocument = gql`
  mutation markMessagesAsHandled($handled: Boolean!, $messagesUuids: [ID]!, $numberUuid: ID!) {
    markMessagesAsHandled(handled: $handled, messagesUuids: $messagesUuids, numberUuid: $numberUuid) {
      uuid
      isHandled
    }
  }
`;
export type MarkMessagesAsHandledMutationFn = Apollo.MutationFunction<
  MarkMessagesAsHandledMutation,
  MarkMessagesAsHandledMutationVariables
>;

/**
 * __useMarkMessagesAsHandledMutation__
 *
 * To run a mutation, you first call `useMarkMessagesAsHandledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessagesAsHandledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessagesAsHandledMutation, { data, loading, error }] = useMarkMessagesAsHandledMutation({
 *   variables: {
 *      handled: // value for 'handled'
 *      messagesUuids: // value for 'messagesUuids'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useMarkMessagesAsHandledMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkMessagesAsHandledMutation, MarkMessagesAsHandledMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkMessagesAsHandledMutation, MarkMessagesAsHandledMutationVariables>(
    MarkMessagesAsHandledDocument,
    options
  );
}
export type MarkMessagesAsHandledMutationHookResult = ReturnType<typeof useMarkMessagesAsHandledMutation>;
export type MarkMessagesAsHandledMutationResult = Apollo.MutationResult<MarkMessagesAsHandledMutation>;
export type MarkMessagesAsHandledMutationOptions = Apollo.BaseMutationOptions<
  MarkMessagesAsHandledMutation,
  MarkMessagesAsHandledMutationVariables
>;
export const AssignMessageNumberTagDocument = gql`
  mutation assignMessageNumberTag($messageUuid: ID!, $numberTagUuid: ID!) {
    assignMessageNumberTag(messageUuid: $messageUuid, numberTagUuid: $numberTagUuid) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type AssignMessageNumberTagMutationFn = Apollo.MutationFunction<
  AssignMessageNumberTagMutation,
  AssignMessageNumberTagMutationVariables
>;

/**
 * __useAssignMessageNumberTagMutation__
 *
 * To run a mutation, you first call `useAssignMessageNumberTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignMessageNumberTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignMessageNumberTagMutation, { data, loading, error }] = useAssignMessageNumberTagMutation({
 *   variables: {
 *      messageUuid: // value for 'messageUuid'
 *      numberTagUuid: // value for 'numberTagUuid'
 *   },
 * });
 */
export function useAssignMessageNumberTagMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignMessageNumberTagMutation, AssignMessageNumberTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AssignMessageNumberTagMutation, AssignMessageNumberTagMutationVariables>(
    AssignMessageNumberTagDocument,
    options
  );
}
export type AssignMessageNumberTagMutationHookResult = ReturnType<typeof useAssignMessageNumberTagMutation>;
export type AssignMessageNumberTagMutationResult = Apollo.MutationResult<AssignMessageNumberTagMutation>;
export type AssignMessageNumberTagMutationOptions = Apollo.BaseMutationOptions<
  AssignMessageNumberTagMutation,
  AssignMessageNumberTagMutationVariables
>;
export const RemoveMessageNumberTagDocument = gql`
  mutation removeMessageNumberTag($messageUuid: ID!, $numberTagUuid: ID!) {
    removeMessageNumberTag(messageUuid: $messageUuid, numberTagUuid: $numberTagUuid) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type RemoveMessageNumberTagMutationFn = Apollo.MutationFunction<
  RemoveMessageNumberTagMutation,
  RemoveMessageNumberTagMutationVariables
>;

/**
 * __useRemoveMessageNumberTagMutation__
 *
 * To run a mutation, you first call `useRemoveMessageNumberTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMessageNumberTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMessageNumberTagMutation, { data, loading, error }] = useRemoveMessageNumberTagMutation({
 *   variables: {
 *      messageUuid: // value for 'messageUuid'
 *      numberTagUuid: // value for 'numberTagUuid'
 *   },
 * });
 */
export function useRemoveMessageNumberTagMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveMessageNumberTagMutation, RemoveMessageNumberTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveMessageNumberTagMutation, RemoveMessageNumberTagMutationVariables>(
    RemoveMessageNumberTagDocument,
    options
  );
}
export type RemoveMessageNumberTagMutationHookResult = ReturnType<typeof useRemoveMessageNumberTagMutation>;
export type RemoveMessageNumberTagMutationResult = Apollo.MutationResult<RemoveMessageNumberTagMutation>;
export type RemoveMessageNumberTagMutationOptions = Apollo.BaseMutationOptions<
  RemoveMessageNumberTagMutation,
  RemoveMessageNumberTagMutationVariables
>;
export const SubmitTextMessageDocument = gql`
  mutation submitTextMessage(
    $chatUuid: ID!
    $content: String!
    $faqUuid: ID
    $optInUuid: ID
    $attachmentId: ID
    $isTranslationEnabled: Boolean
    $translatedContent: String
    $originalLanguageCode: String
    $translatedLanguageCode: String
  ) {
    submitTextMessage(
      chatUuid: $chatUuid
      content: $content
      faqUuid: $faqUuid
      optInUuid: $optInUuid
      attachmentId: $attachmentId
      isTranslationEnabled: $isTranslationEnabled
      translatedContent: $translatedContent
      originalLanguageCode: $originalLanguageCode
      translatedLanguageCode: $translatedLanguageCode
    ) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type SubmitTextMessageMutationFn = Apollo.MutationFunction<
  SubmitTextMessageMutation,
  SubmitTextMessageMutationVariables
>;

/**
 * __useSubmitTextMessageMutation__
 *
 * To run a mutation, you first call `useSubmitTextMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitTextMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitTextMessageMutation, { data, loading, error }] = useSubmitTextMessageMutation({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *      content: // value for 'content'
 *      faqUuid: // value for 'faqUuid'
 *      optInUuid: // value for 'optInUuid'
 *      attachmentId: // value for 'attachmentId'
 *      isTranslationEnabled: // value for 'isTranslationEnabled'
 *      translatedContent: // value for 'translatedContent'
 *      originalLanguageCode: // value for 'originalLanguageCode'
 *      translatedLanguageCode: // value for 'translatedLanguageCode'
 *   },
 * });
 */
export function useSubmitTextMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitTextMessageMutation, SubmitTextMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitTextMessageMutation, SubmitTextMessageMutationVariables>(
    SubmitTextMessageDocument,
    options
  );
}
export type SubmitTextMessageMutationHookResult = ReturnType<typeof useSubmitTextMessageMutation>;
export type SubmitTextMessageMutationResult = Apollo.MutationResult<SubmitTextMessageMutation>;
export type SubmitTextMessageMutationOptions = Apollo.BaseMutationOptions<
  SubmitTextMessageMutation,
  SubmitTextMessageMutationVariables
>;
export const CreateTemplatedMessageDocument = gql`
  mutation createTemplatedMessage($chatUuid: ID!, $template: TemplatedMessageTemplateIo!, $attachmentId: ID) {
    createTemplatedMessage(chatUuid: $chatUuid, template: $template, attachmentId: $attachmentId) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type CreateTemplatedMessageMutationFn = Apollo.MutationFunction<
  CreateTemplatedMessageMutation,
  CreateTemplatedMessageMutationVariables
>;

/**
 * __useCreateTemplatedMessageMutation__
 *
 * To run a mutation, you first call `useCreateTemplatedMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTemplatedMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTemplatedMessageMutation, { data, loading, error }] = useCreateTemplatedMessageMutation({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *      template: // value for 'template'
 *      attachmentId: // value for 'attachmentId'
 *   },
 * });
 */
export function useCreateTemplatedMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTemplatedMessageMutation, CreateTemplatedMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTemplatedMessageMutation, CreateTemplatedMessageMutationVariables>(
    CreateTemplatedMessageDocument,
    options
  );
}
export type CreateTemplatedMessageMutationHookResult = ReturnType<typeof useCreateTemplatedMessageMutation>;
export type CreateTemplatedMessageMutationResult = Apollo.MutationResult<CreateTemplatedMessageMutation>;
export type CreateTemplatedMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateTemplatedMessageMutation,
  CreateTemplatedMessageMutationVariables
>;
export const ScheduleReminderDocument = gql`
  mutation scheduleReminder(
    $accountUuid: ID!
    $numberUuid: ID!
    $segmentUuid: ID
    $query: String!
    $attachmentId: ID
    $scheduledAt: DateTime
    $name: String
    $templateParams: TemplatedMessageTemplateIo!
  ) {
    scheduleReminder(
      accountUuid: $accountUuid
      numberUuid: $numberUuid
      segmentUuid: $segmentUuid
      query: $query
      attachmentId: $attachmentId
      scheduledAt: $scheduledAt
      name: $name
      templateParams: $templateParams
    ) {
      uuid
    }
  }
`;
export type ScheduleReminderMutationFn = Apollo.MutationFunction<
  ScheduleReminderMutation,
  ScheduleReminderMutationVariables
>;

/**
 * __useScheduleReminderMutation__
 *
 * To run a mutation, you first call `useScheduleReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleReminderMutation, { data, loading, error }] = useScheduleReminderMutation({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      numberUuid: // value for 'numberUuid'
 *      segmentUuid: // value for 'segmentUuid'
 *      query: // value for 'query'
 *      attachmentId: // value for 'attachmentId'
 *      scheduledAt: // value for 'scheduledAt'
 *      name: // value for 'name'
 *      templateParams: // value for 'templateParams'
 *   },
 * });
 */
export function useScheduleReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<ScheduleReminderMutation, ScheduleReminderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ScheduleReminderMutation, ScheduleReminderMutationVariables>(
    ScheduleReminderDocument,
    options
  );
}
export type ScheduleReminderMutationHookResult = ReturnType<typeof useScheduleReminderMutation>;
export type ScheduleReminderMutationResult = Apollo.MutationResult<ScheduleReminderMutation>;
export type ScheduleReminderMutationOptions = Apollo.BaseMutationOptions<
  ScheduleReminderMutation,
  ScheduleReminderMutationVariables
>;
export const SearchMessagesDocument = gql`
  mutation searchMessages(
    $numberUuid: ID!
    $query: SearchQueryIo!
    $page: Int
    $pageSize: Int
    $sort: SortType = DESC
  ) {
    searchMessages(numberUuid: $numberUuid, query: $query, page: $page, pageSize: $pageSize, sort: $sort) {
      count
      results {
        ...MessageDetailsWithChatMin
      }
    }
  }
  ${MessageDetailsWithChatMinFragmentDoc}
`;
export type SearchMessagesMutationFn = Apollo.MutationFunction<SearchMessagesMutation, SearchMessagesMutationVariables>;

/**
 * __useSearchMessagesMutation__
 *
 * To run a mutation, you first call `useSearchMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchMessagesMutation, { data, loading, error }] = useSearchMessagesMutation({
 *   variables: {
 *      numberUuid: // value for 'numberUuid'
 *      query: // value for 'query'
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      sort: // value for 'sort'
 *   },
 * });
 */
export function useSearchMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<SearchMessagesMutation, SearchMessagesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SearchMessagesMutation, SearchMessagesMutationVariables>(SearchMessagesDocument, options);
}
export type SearchMessagesMutationHookResult = ReturnType<typeof useSearchMessagesMutation>;
export type SearchMessagesMutationResult = Apollo.MutationResult<SearchMessagesMutation>;
export type SearchMessagesMutationOptions = Apollo.BaseMutationOptions<
  SearchMessagesMutation,
  SearchMessagesMutationVariables
>;
export const CreateChatNoteDocument = gql`
  mutation createChatNote($chatUuid: ID!, $content: String!, $mentions: [ChatNoteMentionIo!]!) {
    createChatNote(chatUuid: $chatUuid, content: $content, mentions: $mentions) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type CreateChatNoteMutationFn = Apollo.MutationFunction<CreateChatNoteMutation, CreateChatNoteMutationVariables>;

/**
 * __useCreateChatNoteMutation__
 *
 * To run a mutation, you first call `useCreateChatNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatNoteMutation, { data, loading, error }] = useCreateChatNoteMutation({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *      content: // value for 'content'
 *      mentions: // value for 'mentions'
 *   },
 * });
 */
export function useCreateChatNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateChatNoteMutation, CreateChatNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateChatNoteMutation, CreateChatNoteMutationVariables>(CreateChatNoteDocument, options);
}
export type CreateChatNoteMutationHookResult = ReturnType<typeof useCreateChatNoteMutation>;
export type CreateChatNoteMutationResult = Apollo.MutationResult<CreateChatNoteMutation>;
export type CreateChatNoteMutationOptions = Apollo.BaseMutationOptions<
  CreateChatNoteMutation,
  CreateChatNoteMutationVariables
>;
export const DeleteChatNoteDocument = gql`
  mutation deleteChatNote($chatNoteUuid: ID!) {
    deleteChatNote(chatNoteUuid: $chatNoteUuid) {
      uuid
    }
  }
`;
export type DeleteChatNoteMutationFn = Apollo.MutationFunction<DeleteChatNoteMutation, DeleteChatNoteMutationVariables>;

/**
 * __useDeleteChatNoteMutation__
 *
 * To run a mutation, you first call `useDeleteChatNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatNoteMutation, { data, loading, error }] = useDeleteChatNoteMutation({
 *   variables: {
 *      chatNoteUuid: // value for 'chatNoteUuid'
 *   },
 * });
 */
export function useDeleteChatNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteChatNoteMutation, DeleteChatNoteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteChatNoteMutation, DeleteChatNoteMutationVariables>(DeleteChatNoteDocument, options);
}
export type DeleteChatNoteMutationHookResult = ReturnType<typeof useDeleteChatNoteMutation>;
export type DeleteChatNoteMutationResult = Apollo.MutationResult<DeleteChatNoteMutation>;
export type DeleteChatNoteMutationOptions = Apollo.BaseMutationOptions<
  DeleteChatNoteMutation,
  DeleteChatNoteMutationVariables
>;
export const ToggleMentionIsClearedDocument = gql`
  mutation toggleMentionIsCleared($isCleared: Boolean!, $mentionUuid: ID!) {
    toggleMentionIsCleared(isCleared: $isCleared, mentionUuid: $mentionUuid) {
      ...ChatNoteMentionFragment
    }
  }
  ${ChatNoteMentionFragmentDoc}
`;
export type ToggleMentionIsClearedMutationFn = Apollo.MutationFunction<
  ToggleMentionIsClearedMutation,
  ToggleMentionIsClearedMutationVariables
>;

/**
 * __useToggleMentionIsClearedMutation__
 *
 * To run a mutation, you first call `useToggleMentionIsClearedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMentionIsClearedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMentionIsClearedMutation, { data, loading, error }] = useToggleMentionIsClearedMutation({
 *   variables: {
 *      isCleared: // value for 'isCleared'
 *      mentionUuid: // value for 'mentionUuid'
 *   },
 * });
 */
export function useToggleMentionIsClearedMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleMentionIsClearedMutation, ToggleMentionIsClearedMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleMentionIsClearedMutation, ToggleMentionIsClearedMutationVariables>(
    ToggleMentionIsClearedDocument,
    options
  );
}
export type ToggleMentionIsClearedMutationHookResult = ReturnType<typeof useToggleMentionIsClearedMutation>;
export type ToggleMentionIsClearedMutationResult = Apollo.MutationResult<ToggleMentionIsClearedMutation>;
export type ToggleMentionIsClearedMutationOptions = Apollo.BaseMutationOptions<
  ToggleMentionIsClearedMutation,
  ToggleMentionIsClearedMutationVariables
>;
export const SendReactionToMessageDocument = gql`
  mutation sendReactionToMessage($emoji: String!, $messageUuid: ID!, $numberUuid: ID!) {
    sendReactionToMessage(emoji: $emoji, messageUuid: $messageUuid, numberUuid: $numberUuid) {
      ...MessageDetails
    }
  }
  ${MessageDetailsFragmentDoc}
`;
export type SendReactionToMessageMutationFn = Apollo.MutationFunction<
  SendReactionToMessageMutation,
  SendReactionToMessageMutationVariables
>;

/**
 * __useSendReactionToMessageMutation__
 *
 * To run a mutation, you first call `useSendReactionToMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReactionToMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReactionToMessageMutation, { data, loading, error }] = useSendReactionToMessageMutation({
 *   variables: {
 *      emoji: // value for 'emoji'
 *      messageUuid: // value for 'messageUuid'
 *      numberUuid: // value for 'numberUuid'
 *   },
 * });
 */
export function useSendReactionToMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<SendReactionToMessageMutation, SendReactionToMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendReactionToMessageMutation, SendReactionToMessageMutationVariables>(
    SendReactionToMessageDocument,
    options
  );
}
export type SendReactionToMessageMutationHookResult = ReturnType<typeof useSendReactionToMessageMutation>;
export type SendReactionToMessageMutationResult = Apollo.MutationResult<SendReactionToMessageMutation>;
export type SendReactionToMessageMutationOptions = Apollo.BaseMutationOptions<
  SendReactionToMessageMutation,
  SendReactionToMessageMutationVariables
>;
export const PreviewTranslationDocument = gql`
  mutation previewTranslation(
    $chatUuid: ID!
    $content: String!
    $originalLanguageCode: String!
    $translatedLanguageCode: String!
  ) {
    previewTranslation(
      chatUuid: $chatUuid
      content: $content
      originalLanguageCode: $originalLanguageCode
      translatedLanguageCode: $translatedLanguageCode
    ) {
      translatedContent
    }
  }
`;
export type PreviewTranslationMutationFn = Apollo.MutationFunction<
  PreviewTranslationMutation,
  PreviewTranslationMutationVariables
>;

/**
 * __usePreviewTranslationMutation__
 *
 * To run a mutation, you first call `usePreviewTranslationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePreviewTranslationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [previewTranslationMutation, { data, loading, error }] = usePreviewTranslationMutation({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *      content: // value for 'content'
 *      originalLanguageCode: // value for 'originalLanguageCode'
 *      translatedLanguageCode: // value for 'translatedLanguageCode'
 *   },
 * });
 */
export function usePreviewTranslationMutation(
  baseOptions?: Apollo.MutationHookOptions<PreviewTranslationMutation, PreviewTranslationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PreviewTranslationMutation, PreviewTranslationMutationVariables>(
    PreviewTranslationDocument,
    options
  );
}
export type PreviewTranslationMutationHookResult = ReturnType<typeof usePreviewTranslationMutation>;
export type PreviewTranslationMutationResult = Apollo.MutationResult<PreviewTranslationMutation>;
export type PreviewTranslationMutationOptions = Apollo.BaseMutationOptions<
  PreviewTranslationMutation,
  PreviewTranslationMutationVariables
>;
export const TranslateMessagesDocument = gql`
  mutation translateMessages(
    $chatUuid: ID!
    $messageUuids: [ID]!
    $accountLanguageCode: String!
    $contactLanguageCode: String!
  ) {
    translateMessages(
      chatUuid: $chatUuid
      messageUuids: $messageUuids
      accountLanguageCode: $accountLanguageCode
      contactLanguageCode: $contactLanguageCode
    ) {
      failedMessagesUuids
    }
  }
`;
export type TranslateMessagesMutationFn = Apollo.MutationFunction<
  TranslateMessagesMutation,
  TranslateMessagesMutationVariables
>;

/**
 * __useTranslateMessagesMutation__
 *
 * To run a mutation, you first call `useTranslateMessagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTranslateMessagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [translateMessagesMutation, { data, loading, error }] = useTranslateMessagesMutation({
 *   variables: {
 *      chatUuid: // value for 'chatUuid'
 *      messageUuids: // value for 'messageUuids'
 *      accountLanguageCode: // value for 'accountLanguageCode'
 *      contactLanguageCode: // value for 'contactLanguageCode'
 *   },
 * });
 */
export function useTranslateMessagesMutation(
  baseOptions?: Apollo.MutationHookOptions<TranslateMessagesMutation, TranslateMessagesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TranslateMessagesMutation, TranslateMessagesMutationVariables>(
    TranslateMessagesDocument,
    options
  );
}
export type TranslateMessagesMutationHookResult = ReturnType<typeof useTranslateMessagesMutation>;
export type TranslateMessagesMutationResult = Apollo.MutationResult<TranslateMessagesMutation>;
export type TranslateMessagesMutationOptions = Apollo.BaseMutationOptions<
  TranslateMessagesMutation,
  TranslateMessagesMutationVariables
>;
