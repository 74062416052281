import React from "react";

import { Nav } from "./Nav";
import { StyledNavs } from "./styles";
import { NavsProps, NavsType } from "./types";

const Navs = ({ type = NavsType.VERTICAL, items, ...props }: NavsProps) => (
  <StyledNavs type={type} {...props}>
    {items.map((navProps, index) => (
      <Nav key={index} {...navProps} />
    ))}
  </StyledNavs>
);

Navs.displayName = "Navs";

export { Navs };
