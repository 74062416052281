/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";

import * as Types from "../../generated/types";

export type ContactFieldMinDetailsFragment = {
  __typename: "ContactField";
  uuid: string | null;
  fieldName: string | null;
  fieldDisplay: string | null;
};

export type NumberMinFragmentFragment = {
  __typename: "Number";
  uuid: string | null;
  vname: string | null;
  fromAddr: string | null;
  profilePicture: string | null;
  numberType: Types.NumberType | null;
  backendType: Types.BackendType | null;
};

export type InitialNumberFragment = {
  __typename: "Number";
  automatorsCount: number | null;
  numberCode: string | null;
  signupType: Types.SignupType | null;
  graphNameStatus: Types.GraphNameStatus | null;
  graphAccountMode: Types.GraphAccountMode | null;
  wabizClientProvisionState: Types.WabizClientProvisionState | null;
  phoneNumberProvider: Types.PhoneNumberProvider | null;
  isRoutingEnabled: boolean | null;
  numberRoute: { __typename: "Number"; uuid: string | null; fromAddr: string | null } | null;
  organisation: {
    __typename: "Organisation";
    uuid: string | null;
    numbers: Array<{
      __typename: "Number";
      uuid: string | null;
      vname: string | null;
      profilePicture: string | null;
      fromAddr: string | null;
    } | null> | null;
  } | null;
  subscription: { __typename: "NumberSubscription"; state: Types.SubscriptionState | null } | null;
  waba: {
    __typename: "Waba";
    fbGraphId: string;
    accountReviewStatus: Types.WabaReviewStatus | null;
    uuid: string;
    facebookBusiness: {
      __typename: "FacebookBusiness";
      uuid: string;
      verificationStatus: Types.FacebookBusinessVerificationStatus | null;
    } | null;
  } | null;
} & NumberMinFragmentFragment;

export const ContactFieldMinDetailsFragmentDoc = gql`
  fragment ContactFieldMinDetails on ContactField {
    uuid
    fieldName
    fieldDisplay
  }
`;
export const NumberMinFragmentFragmentDoc = gql`
  fragment NumberMinFragment_ on Number {
    uuid
    vname
    fromAddr
    profilePicture
    numberType
    backendType
  }
`;
export const InitialNumberFragmentDoc = gql`
  fragment InitialNumber_ on Number {
    ...NumberMinFragment_
    automatorsCount
    numberCode
    signupType
    graphNameStatus
    graphAccountMode
    wabizClientProvisionState
    phoneNumberProvider
    isRoutingEnabled
    numberRoute {
      uuid
      fromAddr
    }
    organisation {
      uuid
      numbers {
        uuid
        vname
        profilePicture
        fromAddr
      }
    }
    subscription {
      state
    }
    waba {
      fbGraphId
      accountReviewStatus
      uuid
      facebookBusiness {
        uuid
        verificationStatus
      }
    }
  }
  ${NumberMinFragmentFragmentDoc}
`;
