import gql from "graphql-tag";

import { boundary } from "api/boundary";

import { SETTINGS_NUMBER_FRAGMENT } from "./fragments";

export const GET_SETTINGS_NUMBER = {
  query: gql`
    query getNumberSettings($numberUuid: ID!) {
      number(numberUuid: $numberUuid) {
        ...SettingsNumber
      }
    }

    ${SETTINGS_NUMBER_FRAGMENT.query}
  `,
  parse: boundary({
    number: SETTINGS_NUMBER_FRAGMENT.parse,
  }),
};
