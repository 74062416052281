import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

export const NUMBER_MIN_FRAGMENT = {
  query: gql`
    fragment NumberMinFragment on Number {
      uuid
      vname
      fromAddr
      profilePicture
      numberType
      backendType
    }
  `,
  parse: {
    uuid: required(),
    vname: required(),
    fromAddr: required(),
    numberType: required(),
  },
};

export const INITIAL_NUMBER_FRAGMENT = {
  query: gql`
    fragment InitialNumber on Number {
      ...NumberMinFragment
      automatorsCount
      allowMessagingWithInvalidSubscription
      billingEnabled
      numberCode
      signupType
      graphNameStatus
      graphAccountMode
      wabizClientProvisionState
      phoneNumberProvider
      isRoutingEnabled
      numberRoute {
        uuid
        fromAddr
      }
      organisation {
        uuid
        numbers {
          uuid
          vname
          profilePicture
          fromAddr
        }
      }
      subscription {
        state
      }
      waba {
        fbGraphId
        accountReviewStatus
        uuid
        facebookBusiness {
          uuid
          verificationStatus
        }
      }
    }

    ${NUMBER_MIN_FRAGMENT.query}
  `,
  parse: {
    ...NUMBER_MIN_FRAGMENT.parse,
    organisation: {
      uuid: required(),
      numbers: boundary({
        uuid: required(),
        fromAddr: required(),
      }),
    },
  },
};

export const NUMBER_FOR_COLLECTION_FRAGMENT = gql`
  fragment NumberForCollection on Number {
    uuid
    backendType
    fromAddr
    numberCode
    numberType
    numberRoute {
      uuid
      fromAddr
    }
  }
`;

export const CONTACT_FIELD_FRAGMENT = gql`
  fragment ContactFieldDetails on ContactField {
    uuid
    fieldName
    fieldType
    fieldDisplay
    fieldSpec {
      defaultValue
      validatorType
      validatorParams
    }
  }
`;

export const NUMBER_STATUS_FRAGMENT = {
  query: gql`
    fragment NumberStatusFragment on Number {
      uuid
      graphStatus
      graphQualityScore
      whatsappTier
    }
  `,
  parse: {
    graphStatus: required(),
    graphQualityScore: required(),
    whatsappTier: required(),
  },
};
