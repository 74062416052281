/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";

import { ChatMediumFragment } from "../../chats/generated/fragments.generated";
import { ChatMinFragment } from "../../chats/generated/fragments.generated";
import { ChatMediumFragmentDoc } from "../../chats/generated/fragments.generated";
import { ChatMinFragmentDoc } from "../../chats/generated/fragments.generated";
import * as Types from "../../generated/types";

export type ChatNoteMentionFragment = {
  __typename: "ChatNoteMention";
  authorAccountUuid: string;
  isCleared: boolean;
  taggedAccountUuid: string;
  uuid: string;
};

export type MessageTranslationFragment = {
  __typename: "MessageTranslation";
  originalLanguageCode: string | null;
  translatedLanguageCode: string | null;
  translatedMessage: string | null;
  uuid: string | null;
};

export type MessageMinFragment = {
  __typename: "Message";
  uuid: string | null;
  content: string | null;
  messageType: Types.MessageType | null;
  isHandled: boolean | null;
};

export type MessageInteractiveDetailsFragment = {
  __typename: "Message";
  uuid: string | null;
  interactive: {
    __typename: "Interactive";
    type: Types.InteractiveType | null;
    header: {
      __typename: "InteractiveHeader";
      type: Types.InteractiveHeaderType | null;
      text: string | null;
      video: any | null;
      image: any | null;
      document: any | null;
    } | null;
    body: { __typename: "InteractiveBody"; text: string | null } | null;
    footer: { __typename: "InteractiveFooter"; text: string | null } | null;
    action: {
      __typename: "InteractiveAction";
      button: string | null;
      buttons: Array<{
        __typename: "InteractiveButton";
        type: Types.InteractiveButtonType | null;
        reply: { __typename: "InteractiveQuickReplyButton"; title: string | null; id: string | null } | null;
      } | null> | null;
      sections: Array<{
        __typename: "InteractiveListSection";
        title: string | null;
        rows: Array<{
          __typename: "InteractiveListSectionRow";
          id: string | null;
          title: string | null;
          description: string | null;
        } | null> | null;
      } | null> | null;
    } | null;
    buttonReply: { __typename: "ButtonReply"; id: string | null; title: string | null } | null;
    listReply: { __typename: "ListReply"; id: string | null; title: string | null; description: string | null } | null;
  } | null;
};

export type MessageDetailsFragment = {
  __typename: "Message";
  fromAddr: string | null;
  direction: Types.DirectionType | null;
  lastStatus: Types.StatusType | null;
  lastStatusTimestamp: any | null;
  insertedAt: any | null;
  sourceType: Types.SourceTypeType | null;
  templateButtonText: string | null;
  templateButtonIndex: number | null;
  templateButtonTemplateName: string | null;
  templateButtonTemplateLanguage: string | null;
  externalTimestamp: any | null;
  updatedAt: any | null;
  isDeleted: boolean | null;
  deletedReason: Types.DeletedReasonType | null;
  chat: ({ __typename: "Chat" } & ChatMediumFragment) | null;
  chatNoteMentions: Array<{ __typename: "ChatNoteMention" } & ChatNoteMentionFragment>;
  inReplyTo:
    | ({
        __typename: "Message";
        content: string | null;
        messageType: Types.MessageType | null;
        direction: Types.DirectionType | null;
        updatedAt: any | null;
        insertedAt: any | null;
        template: {
          __typename: "Template";
          name: string | null;
          language: { __typename: "HsmLanguage"; code: string | null } | null;
          components: Array<{
            __typename: "MessageTemplateComponent";
            type: Types.MessageTemplateComponentType | null;
            parameters: Array<
              | {
                  __typename: "MessageTemplateComponentParameterDocument";
                  type: Types.MessageTemplateComponentParameterType | null;
                  document: {
                    __typename: "MessageTemplateHeaderMediaParametersDocument";
                    id: string | null;
                    link: string | null;
                    filename: string | null;
                  } | null;
                }
              | {
                  __typename: "MessageTemplateComponentParameterImage";
                  type: Types.MessageTemplateComponentParameterType | null;
                  image: {
                    __typename: "MessageTemplateHeaderMediaParameters";
                    id: string | null;
                    link: string | null;
                  } | null;
                }
              | {
                  __typename: "MessageTemplateComponentParameterPayload";
                  type: Types.MessageTemplateComponentParameterType | null;
                }
              | {
                  __typename: "MessageTemplateComponentParameterText";
                  text: string | null;
                  type: Types.MessageTemplateComponentParameterType | null;
                }
              | {
                  __typename: "MessageTemplateComponentParameterVideo";
                  type: Types.MessageTemplateComponentParameterType | null;
                  video: {
                    __typename: "MessageTemplateHeaderMediaParameters";
                    id: string | null;
                    link: string | null;
                  } | null;
                }
              | null
            > | null;
          } | null> | null;
        } | null;
        hsm: {
          __typename: "Hsm";
          elementName: string | null;
          language: { __typename: "HsmLanguage"; code: string | null } | null;
          localizableParams: Array<{ __typename: "LocalizableParam"; default: string | null } | null> | null;
        } | null;
        author: { __typename: "Author"; id: string | null; type: Types.AuthorType | null; name: string | null } | null;
        messageAttachments: Array<{
          __typename: "MessageAttachment";
          insertedAt: any | null;
          caption: string | null;
          attachment: {
            __typename: "Attachment";
            id: string | null;
            mediaType: Types.MediaType | null;
            mimeType: string | null;
            mediaObject: any | null;
            uri: string | null;
            autoCaption: string | null;
            autoCaptionStatus: Types.TranscriptionsCaptionsAndTranslationsStatusType | null;
            transcriptionsStatus: Types.TranscriptionsCaptionsAndTranslationsStatusType | null;
            transcriptions: Array<{
              __typename: "AttachmentTranscription";
              text: string | null;
              startTimestampSeconds: number | null;
              endTimestampSeconds: number | null;
            } | null> | null;
          } | null;
        } | null> | null;
      } & MessageInteractiveDetailsFragment)
    | null;
  messageTags: Array<{
    __typename: "MessageTag";
    confidence: number | null;
    insertedAt: any | null;
    numberTag: { __typename: "NumberTag"; uuid: string | null; value: string | null; color: string | null } | null;
  } | null> | null;
  hsm: {
    __typename: "Hsm";
    elementName: string | null;
    language: { __typename: "HsmLanguage"; code: string | null } | null;
    localizableParams: Array<{ __typename: "LocalizableParam"; default: string | null } | null> | null;
  } | null;
  template: {
    __typename: "Template";
    name: string | null;
    language: { __typename: "HsmLanguage"; code: string | null } | null;
    components: Array<{
      __typename: "MessageTemplateComponent";
      type: Types.MessageTemplateComponentType | null;
      subType: Types.MessageTemplateComponentSubType | null;
      index: number | null;
      parameters: Array<
        | {
            __typename: "MessageTemplateComponentParameterDocument";
            type: Types.MessageTemplateComponentParameterType | null;
            document: {
              __typename: "MessageTemplateHeaderMediaParametersDocument";
              id: string | null;
              link: string | null;
              filename: string | null;
            } | null;
          }
        | {
            __typename: "MessageTemplateComponentParameterImage";
            type: Types.MessageTemplateComponentParameterType | null;
            image: {
              __typename: "MessageTemplateHeaderMediaParameters";
              id: string | null;
              link: string | null;
            } | null;
          }
        | {
            __typename: "MessageTemplateComponentParameterPayload";
            type: Types.MessageTemplateComponentParameterType | null;
          }
        | {
            __typename: "MessageTemplateComponentParameterText";
            text: string | null;
            type: Types.MessageTemplateComponentParameterType | null;
          }
        | {
            __typename: "MessageTemplateComponentParameterVideo";
            type: Types.MessageTemplateComponentParameterType | null;
            video: {
              __typename: "MessageTemplateHeaderMediaParameters";
              id: string | null;
              link: string | null;
            } | null;
          }
        | null
      > | null;
    } | null> | null;
  } | null;
  author: { __typename: "Author"; id: string | null; type: Types.AuthorType | null; name: string | null } | null;
  messageAttachments: Array<{
    __typename: "MessageAttachment";
    insertedAt: any | null;
    caption: string | null;
    attachment: {
      __typename: "Attachment";
      id: string | null;
      mediaType: Types.MediaType | null;
      mimeType: string | null;
      mediaObject: any | null;
      uri: string | null;
      autoCaption: string | null;
      autoCaptionStatus: Types.TranscriptionsCaptionsAndTranslationsStatusType | null;
      transcriptionsStatus: Types.TranscriptionsCaptionsAndTranslationsStatusType | null;
      transcriptions: Array<{
        __typename: "AttachmentTranscription";
        text: string | null;
        startTimestampSeconds: number | null;
        endTimestampSeconds: number | null;
      } | null> | null;
    } | null;
  } | null> | null;
  deletedByActor: {
    __typename: "Actor";
    uuid: string | null;
    type: Types.ActorType | null;
    account: {
      __typename: "Account";
      profilePicture: string | null;
      uuid: string | null;
      firstName: string | null;
      lastName: string | null;
      email: string | null;
    } | null;
  } | null;
  messageReactions: Array<{ __typename: "MessageReaction"; direction: Types.DirectionType; reaction: string }>;
  messageTranslations: Array<({ __typename: "MessageTranslation" } & MessageTranslationFragment) | null> | null;
} & MessageMinFragment &
  MessageInteractiveDetailsFragment;

export type MessageDetailsWithChatMinFragment = {
  __typename: "Message";
  chat: ({ __typename: "Chat" } & ChatMinFragment) | null;
} & MessageDetailsFragment;

export type MessageDetailsWithChatMediumFragment = {
  __typename: "Message";
  chat: ({ __typename: "Chat" } & ChatMediumFragment) | null;
} & MessageDetailsFragment;

export const MessageMinFragmentDoc = gql`
  fragment MessageMin on Message {
    uuid
    content
    messageType
    isHandled
  }
`;
export const MessageInteractiveDetailsFragmentDoc = gql`
  fragment MessageInteractiveDetails on Message {
    uuid
    interactive {
      type
      header {
        type
        text
        video
        image
        document
      }
      body {
        text
      }
      footer {
        text
      }
      action {
        button
        buttons {
          type
          reply {
            title
            id
          }
        }
        sections {
          title
          rows {
            id
            title
            description
          }
        }
      }
      buttonReply {
        id
        title
      }
      listReply {
        id
        title
        description
      }
    }
  }
`;
export const ChatNoteMentionFragmentDoc = gql`
  fragment ChatNoteMentionFragment on ChatNoteMention {
    authorAccountUuid
    isCleared
    taggedAccountUuid
    uuid
  }
`;
export const MessageTranslationFragmentDoc = gql`
  fragment MessageTranslationFragment on MessageTranslation {
    originalLanguageCode
    translatedLanguageCode
    translatedMessage
    uuid
  }
`;
export const MessageDetailsFragmentDoc = gql`
  fragment MessageDetails on Message {
    ...MessageMin
    ...MessageInteractiveDetails
    fromAddr
    direction
    lastStatus
    lastStatusTimestamp
    insertedAt
    chat {
      ...ChatMedium
    }
    chatNoteMentions {
      ...ChatNoteMentionFragment
    }
    sourceType
    inReplyTo {
      ...MessageInteractiveDetails
      content
      messageType
      direction
      updatedAt
      insertedAt
      template {
        name
        language {
          code
        }
        components {
          type
          parameters {
            type
            ... on MessageTemplateComponentParameterText {
              text
            }
            ... on MessageTemplateComponentParameterVideo {
              video {
                id
                link
              }
            }
            ... on MessageTemplateComponentParameterImage {
              image {
                id
                link
              }
            }
            ... on MessageTemplateComponentParameterDocument {
              document {
                id
                link
                filename
              }
            }
          }
        }
      }
      hsm {
        elementName
        language {
          code
        }
        localizableParams {
          default
        }
      }
      author {
        id
        type
        name
      }
      messageAttachments {
        insertedAt
        attachment {
          id
          mediaType
          mimeType
          mediaObject
          uri
          autoCaption
          autoCaptionStatus
          transcriptions {
            text
            startTimestampSeconds
            endTimestampSeconds
          }
          transcriptionsStatus
        }
        caption
      }
    }
    messageTags {
      confidence
      insertedAt
      numberTag {
        uuid
        value
        color
      }
    }
    hsm {
      elementName
      language {
        code
      }
      localizableParams {
        default
      }
    }
    templateButtonText
    templateButtonIndex
    templateButtonTemplateName
    templateButtonTemplateLanguage
    template {
      name
      language {
        code
      }
      components {
        type
        subType
        index
        parameters {
          type
          ... on MessageTemplateComponentParameterText {
            text
          }
          ... on MessageTemplateComponentParameterVideo {
            video {
              id
              link
            }
          }
          ... on MessageTemplateComponentParameterImage {
            image {
              id
              link
            }
          }
          ... on MessageTemplateComponentParameterDocument {
            document {
              id
              link
              filename
            }
          }
        }
      }
    }
    author {
      id
      type
      name
    }
    messageAttachments {
      insertedAt
      attachment {
        id
        mediaType
        mimeType
        mediaObject
        uri
        autoCaption
        autoCaptionStatus
        transcriptions {
          text
          startTimestampSeconds
          endTimestampSeconds
        }
        transcriptionsStatus
      }
      caption
    }
    externalTimestamp
    updatedAt
    insertedAt
    isDeleted
    deletedReason
    deletedByActor {
      uuid
      type
      account {
        profilePicture
        uuid
        firstName
        lastName
        email
      }
    }
    messageReactions {
      direction
      reaction
    }
    messageTranslations {
      ...MessageTranslationFragment
    }
  }
  ${MessageMinFragmentDoc}
  ${MessageInteractiveDetailsFragmentDoc}
  ${ChatMediumFragmentDoc}
  ${ChatNoteMentionFragmentDoc}
  ${MessageTranslationFragmentDoc}
`;
export const MessageDetailsWithChatMinFragmentDoc = gql`
  fragment MessageDetailsWithChatMin on Message {
    ...MessageDetails
    chat {
      ...ChatMin
    }
  }
  ${MessageDetailsFragmentDoc}
  ${ChatMinFragmentDoc}
`;
export const MessageDetailsWithChatMediumFragmentDoc = gql`
  fragment MessageDetailsWithChatMedium on Message {
    ...MessageDetails
    chat {
      ...ChatMedium
    }
  }
  ${MessageDetailsFragmentDoc}
  ${ChatMediumFragmentDoc}
`;
