import i18n from "i18next";
import _capitalize from "lodash/capitalize";
import { initReactI18next } from "react-i18next";

import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";

const resources = {
  en: {
    common: common_en,
  },
  de: {
    common: common_de,
  },
};

const options = {
  lng: "en",
  debug: false,
  resources,
  interpolation: {
    escapeValue: false,
    format: (value, format) =>
      value
        ? format === "quotes"
          ? `"${value}"`
          : format === "uppercase"
            ? value.toUpperCase()
            : format === "lowercase"
              ? value.toLowerCase()
              : format === "capitalize"
                ? _capitalize(value)
                : value
        : value,
  },
  defaultNS: "common",
};

i18n.use(initReactI18next).init(options);

export default i18n;
