import { TOrganisationDetails } from "graphql/organisation";
import { NumberType } from "graphqlQueries/generated/globalTypes";
import { getLastNumberUuid } from "utils/localStorage";

export const findOrganisation = (organisations?: TOrganisationDetails[] | null, organisationUuid?: string) =>
  organisations?.find((o) => o.uuid === organisationUuid);

export const findFirstOrganisationWithNumber = (organisations?: TOrganisationDetails[] | null) =>
  organisations?.find((org) => org.numbers.length > 0);

export const getOrganisationByUuid = (organisations?: TOrganisationDetails[] | null, organisationUuid?: string) =>
  findOrganisation(organisations, organisationUuid) ?? organisations?.[0];

export const findOrganisationByNumber = (organisations?: TOrganisationDetails[], numberUuid?: string) =>
  organisations?.find((o) => o.numbers.some((n) => n.uuid === numberUuid));

export const getOrganisationByNumber = (organisations?: TOrganisationDetails[], numberUuid?: string) =>
  findOrganisationByNumber(organisations, numberUuid) ?? organisations?.[0];

export const isOrganisationNumber = (organisation?: TOrganisationDetails, numberUuid?: string) =>
  organisation?.numbers.some((n) => n.uuid === numberUuid) ?? false;

export const isAdmin = (organisation?: TOrganisationDetails) =>
  organisation?.organisationAccounts.some(
    ({ account, isAdmin }) => account?.uuid === organisation.myAccount.uuid && isAdmin
  ) ?? false;

export const selectLastUsedNumberUuid = (organisation: TOrganisationDetails) => {
  const lastNumberUuid = getLastNumberUuid(organisation.uuid);

  return isOrganisationNumber(organisation, lastNumberUuid) ? lastNumberUuid : organisation.numbers[0]?.uuid;
};

export const hasDirectNumber = (organisation?: TOrganisationDetails) =>
  organisation?.numbers.some((n) => n.numberType === NumberType.DIRECT_NUMBER) ?? false;

export const getSandboxNumberUuid = (organisation?: TOrganisationDetails) =>
  organisation?.numbers.find((n) => n.numberType === NumberType.VIRTUAL_NUMBER)?.uuid;

export const getCurrentUserName = (organisation?: TOrganisationDetails) =>
  `${organisation?.myAccount?.firstName} ${organisation?.myAccount?.lastName}`;

export const getNumberByUuid = (organisation?: TOrganisationDetails, numberUuid?: string) =>
  organisation?.numbers.find((number) => number.uuid === numberUuid);
