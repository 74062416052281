import { IconsListType } from "@backlight-dev/turnio.design-icons/Icons/types";
import { ValueOf } from "ds/types";
import React from "react";

export const NavType = {
  MAIN: "main",
  FEATURE: "feature",
  ICON: "icon",
} as const;
export type TNavType = ValueOf<typeof NavType>;

export const NavsType = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
} as const;
export type TNavsType = ValueOf<typeof NavsType>;

export interface NavItemProps {
  counter?: {
    value: number;
    unread?: boolean;
  };
  type?: TNavType;
  iconName?: IconsListType;
  text?: string;
  version?: string;
  current?: boolean;
  onClick?: () => void;
  anchor?: React.ComponentPropsWithoutRef<"a">;
}

export interface NavsProps extends React.ComponentPropsWithoutRef<"ul"> {
  type?: TNavsType;
  items: NavItemProps[];
}
