import React from "react";
import { matchPath, useLocation } from "react-router-dom";

import { RouteWithNavigationProps } from "./RouteWithNavigation";

export const useRouteNavigationProps = (children: React.ReactNode) => {
  const location = useLocation();
  const isRouteWithNavigationProps = (
    children: React.ReactNode
  ): children is React.ReactElement<RouteWithNavigationProps> =>
    React.isValidElement(children) &&
    Boolean(children.props.navigationHeaderProps || children.props.tabs || children.props.mainNavigationProps);

  const routes = React.Children.map(children, (child) => (isRouteWithNavigationProps(child) ? child.props : null));

  const activeRoute = routes?.find((route) => matchPath(location.pathname, { path: route.path, exact: route.exact }));
  const activeTab = activeRoute?.tabs?.find((tab) =>
    matchPath(location.pathname, { path: `${activeRoute.path}${tab.path}`, exact: tab.exact })
  );

  const { navigationHeaderProps, mainNavigationProps } = activeRoute ?? {};

  return {
    navigationHeaderProps: {
      ...navigationHeaderProps,
      ...activeTab,
    },
    mainNavigationProps,
  };
};
