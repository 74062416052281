export const LIVEVIEW_REQUEST_REACT_OPEN_NAV = "LIVEVIEW_REQUEST_REACT_OPEN_NAV";
export const LIVEVIEW_REQUEST_REACT_NAVIGATE = "LIVEVIEW_REQUEST_REACT_NAVIGATE";
export const LIVEVIEW_REQUEST_SHOW_OVERLAY = "LIVEVIEW_REQUEST_SHOW_OVERLAY";
export const LIVEVIEW_REQUEST_HIDE_OVERLAY = "LIVEVIEW_REQUEST_HIDE_OVERLAY";
export const REACT_CHANGED_VIEWPORT = "REACT_CHANGED_VIEWPORT";
export const REACT_NAVIGATED = "REACT_NAVIGATED";
export const VIEWPORT_DESKTOP = "VIEWPORT_DESKTOP";
export const VIEWPORT_MOBILE = "VIEWPORT_MOBILE";

export const EVENT_LIVEVIEW_ACTIVE = "EVENT_LIVEVIEW_ACTIVE";
