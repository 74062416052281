import { IconsListType } from "@backlight-dev/turnio.design-icons/Icons/types";
import _get from "lodash/get";
import { useTranslation } from "react-i18next";
import { RouteProps, matchPath, useLocation } from "react-router-dom";

import { TInboxGroupingSummary } from "graphql/inbox";
import { useGlobalContext, useNavigation } from "hooks";
import { Override } from "types";
import { TFeatureFlag, isEnabledFeature } from "utils/featureFlags";

type TRouteProps = Override<RouteProps, { path: string }>;
type TRoute = {
  config: TRouteProps;
  routes?: TRoutes;
};
type TRoutes = Record<string, TRoute>;

export const rootConfig = {
  path: "",
};
export const routes: TRoutes = {
  numberDetail: {
    config: {
      path: "/number/:numberUuid",
    },
    routes: {
      conversations: {
        config: {
          path: "",
          exact: true,
        },
      },
      inbox: {
        config: {
          path: "/inbox",
          exact: true,
        },
      },
      content: {
        config: {
          path: "/content",
        },
      },
      people: {
        config: {
          path: "/people/",
        },
      },
      journeys: {
        config: {
          path: "/journeys",
        },
      },
      reminders: {
        config: {
          path: "/reminders",
          exact: true,
        },
      },
      playbooks: {
        config: {
          path: "/playbooks",
        },
      },
      insights: {
        config: {
          path: "/insights",
          exact: true,
        },
      },
      settings: {
        config: {
          path: "/settings",
        },
      },
    },
  },
};

const getConfig = (routeConfigPath: string) => {
  const configPath = routeConfigPath.split(".").join(".routes.");

  return _get(routes, configPath, { config: rootConfig }).config;
};
const getFullPath = (routeConfigPath: string, childRoutePath = "") => {
  const config = getConfig(routeConfigPath);
  const routeConfigPathKeys = routeConfigPath.split(".");
  const path = config.path + childRoutePath;

  if (routeConfigPathKeys.length > 1) {
    routeConfigPathKeys.pop();

    const parentRouteConfigPath = routeConfigPathKeys.join(".");

    return getFullPath(parentRouteConfigPath, path);
  }

  return path;
};

export const getRouteConfig = (routeConfigPath: string, fullPath = true): TRouteProps => {
  const config = getConfig(routeConfigPath);

  return {
    ...config,
    path: fullPath ? getFullPath(routeConfigPath) : config.path,
  };
};

interface IMenuItemConfig {
  id: string;
  iconName?: IconsListType;
  stickerText?: string;
  feature?: TFeatureFlag;
  hideOnFeature?: TFeatureFlag;
}

const createMenuItem = ({ id, iconName, stickerText }: IMenuItemConfig) => ({
  id,
  titleKey: `menu.${id}`,
  route: getRouteConfig(`numberDetail.${id}`),
  stickerText,
  iconName,
});

export const useMenu = (yourInboxStats?: TInboxGroupingSummary | null) => {
  const { navigateTo } = useNavigation();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { numberUuid, setVisibleMenu } = useGlobalContext();
  const getRoutePath = (itemRoute: TRouteProps) => itemRoute.path.replace(":numberUuid", numberUuid);

  const onClick = (to: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setVisibleMenu(false);
    navigateTo(to)();
  };

  const menuConfig: IMenuItemConfig[] = [
    {
      id: "inbox",
      iconName: "MessageChatCircleIcon",
    },
    {
      id: "content",
      iconName: "FileHeart_02Icon",
    },
    {
      id: "journeys",
      iconName: "Tool_02Icon",
    },
    {
      id: "reminders",
      iconName: "BellRinging_04Icon",
    },
    {
      id: "people",
      iconName: "User_01Icon",
    },
    {
      id: "playbooks",
      iconName: "BookOpen_01Icon",
    },
    {
      id: "insights",
      iconName: "PieChart_04Icon",
    },
    {
      id: "settings",
      iconName: "Settings_01Icon",
    },
  ];

  return menuConfig
    .filter(
      ({ feature, hideOnFeature }) =>
        (!feature || isEnabledFeature(feature)) && (!hideOnFeature || !isEnabledFeature(hideOnFeature))
    )
    .map(createMenuItem)
    .map(({ id, titleKey, route, stickerText, iconName }) => {
      const current = !!matchPath(pathname, route);
      const to = getRoutePath(route);
      const { count = 0, unread } = yourInboxStats ?? {};

      return {
        text: t(titleKey),
        current,
        version: stickerText,
        iconName,
        counter:
          titleKey === "menu.inbox" && count && count > 0
            ? {
                value: count,
                unread: unread ?? false,
              }
            : undefined,
        anchor: {
          onClick: onClick(to),
          href: to,
        },
        className: titleKey === "menu.conversations" ? "guide-2" : undefined,
        "data-test": current ? "active-menu-item" : "menu-item",
        "data-test-target": id,
      };
    });
};
