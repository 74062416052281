/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";

import * as Types from "../../generated/types";
import { AccountDetailsFragment, OrganisationAccountDetailsFragment } from "./fragments.generated";
import { AccountDetailsFragmentDoc, OrganisationAccountDetailsFragmentDoc } from "./fragments.generated";

const defaultOptions = {} as const;
export type CreateAccountMutationVariables = Types.Exact<{
  email: Types.Scalars["String"];
  organisationUuid: Types.Scalars["ID"];
}>;

export type CreateAccountMutation = {
  __typename: "RootMutationType";
  createAccount: ({ __typename: "OrganisationAccount" } & OrganisationAccountDetailsFragment) | null;
};

export type CreateAccountsMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  accountInputs?: Types.InputMaybe<Array<Types.InputMaybe<Types.AccountInput>> | Types.InputMaybe<Types.AccountInput>>;
}>;

export type CreateAccountsMutation = {
  __typename: "RootMutationType";
  createAccounts: Array<({ __typename: "OrganisationAccount" } & OrganisationAccountDetailsFragment) | null> | null;
};

export type DeleteAccountMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  accountUuid: Types.Scalars["ID"];
}>;

export type DeleteAccountMutation = {
  __typename: "RootMutationType";
  deleteAccount: ({ __typename: "OrganisationAccount" } & OrganisationAccountDetailsFragment) | null;
};

export type UpdateOrganisationAccountMutationVariables = Types.Exact<{
  organisationUuid: Types.Scalars["ID"];
  accountUuid: Types.Scalars["ID"];
  isAdmin: Types.Scalars["Boolean"];
}>;

export type UpdateOrganisationAccountMutation = {
  __typename: "RootMutationType";
  updateOrganisationAccount: ({ __typename: "OrganisationAccount" } & OrganisationAccountDetailsFragment) | null;
};

export const CreateAccountDocument = gql`
  mutation createAccount($email: String!, $organisationUuid: ID!) {
    createAccount(email: $email, organisationUuid: $organisationUuid) {
      ...OrganisationAccountDetails
    }
  }
  ${OrganisationAccountDetailsFragmentDoc}
`;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      organisationUuid: // value for 'organisationUuid'
 *   },
 * });
 */
export function useCreateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
}
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export const CreateAccountsDocument = gql`
  mutation createAccounts($organisationUuid: ID!, $accountInputs: [AccountInput]) {
    createAccounts(organisationUuid: $organisationUuid, accountInputs: $accountInputs) {
      ...OrganisationAccountDetails
    }
  }
  ${OrganisationAccountDetailsFragmentDoc}
`;
export type CreateAccountsMutationFn = Apollo.MutationFunction<CreateAccountsMutation, CreateAccountsMutationVariables>;

/**
 * __useCreateAccountsMutation__
 *
 * To run a mutation, you first call `useCreateAccountsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountsMutation, { data, loading, error }] = useCreateAccountsMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      accountInputs: // value for 'accountInputs'
 *   },
 * });
 */
export function useCreateAccountsMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountsMutation, CreateAccountsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAccountsMutation, CreateAccountsMutationVariables>(CreateAccountsDocument, options);
}
export type CreateAccountsMutationHookResult = ReturnType<typeof useCreateAccountsMutation>;
export type CreateAccountsMutationResult = Apollo.MutationResult<CreateAccountsMutation>;
export type CreateAccountsMutationOptions = Apollo.BaseMutationOptions<
  CreateAccountsMutation,
  CreateAccountsMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation deleteAccount($organisationUuid: ID!, $accountUuid: ID!) {
    deleteAccount(organisationUuid: $organisationUuid, accountUuid: $accountUuid) {
      ...OrganisationAccountDetails
    }
  }
  ${OrganisationAccountDetailsFragmentDoc}
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      accountUuid: // value for 'accountUuid'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const UpdateOrganisationAccountDocument = gql`
  mutation updateOrganisationAccount($organisationUuid: ID!, $accountUuid: ID!, $isAdmin: Boolean!) {
    updateOrganisationAccount(organisationUuid: $organisationUuid, accountUuid: $accountUuid, isAdmin: $isAdmin) {
      ...OrganisationAccountDetails
    }
  }
  ${OrganisationAccountDetailsFragmentDoc}
`;
export type UpdateOrganisationAccountMutationFn = Apollo.MutationFunction<
  UpdateOrganisationAccountMutation,
  UpdateOrganisationAccountMutationVariables
>;

/**
 * __useUpdateOrganisationAccountMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationAccountMutation, { data, loading, error }] = useUpdateOrganisationAccountMutation({
 *   variables: {
 *      organisationUuid: // value for 'organisationUuid'
 *      accountUuid: // value for 'accountUuid'
 *      isAdmin: // value for 'isAdmin'
 *   },
 * });
 */
export function useUpdateOrganisationAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganisationAccountMutation,
    UpdateOrganisationAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganisationAccountMutation, UpdateOrganisationAccountMutationVariables>(
    UpdateOrganisationAccountDocument,
    options
  );
}
export type UpdateOrganisationAccountMutationHookResult = ReturnType<typeof useUpdateOrganisationAccountMutation>;
export type UpdateOrganisationAccountMutationResult = Apollo.MutationResult<UpdateOrganisationAccountMutation>;
export type UpdateOrganisationAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganisationAccountMutation,
  UpdateOrganisationAccountMutationVariables
>;
