import gql from "graphql-tag";

import { required } from "api/boundary";

export const ACCOUNT_DETAILS_FRAGMENT = {
  query: gql`
    fragment AccountDetails on Account {
      email
      firstName
      languages
      lastName
      preferredLanguage
      profilePicture
      uuid
    }
  `,
  parse: {
    uuid: required(),
    email: required(),
  },
};

export const ORGANISATION_ACCOUNT_DETAILS_FRAGMENT = {
  query: gql`
    fragment OrganisationAccountDetails on OrganisationAccount {
      isAdmin
      account {
        ...AccountDetails
      }
    }

    ${ACCOUNT_DETAILS_FRAGMENT.query}
  `,
  parse: {
    account: ACCOUNT_DETAILS_FRAGMENT.parse,
  },
};
