/* tslint:disable */

/* eslint-disable */
import { gql } from "@apollo/client";

import * as Types from "../../generated/types";

export type AccountDetailsFragment = {
  __typename: "Account";
  email: string | null;
  firstName: string | null;
  languages: Array<string | null> | null;
  lastName: string | null;
  preferredLanguage: string | null;
  profilePicture: string | null;
  uuid: string | null;
};

export type OrganisationAccountDetailsFragment = {
  __typename: "OrganisationAccount";
  isAdmin: boolean | null;
  account: ({ __typename: "Account" } & AccountDetailsFragment) | null;
};

export const AccountDetailsFragmentDoc = gql`
  fragment AccountDetails on Account {
    email
    firstName
    languages
    lastName
    preferredLanguage
    profilePicture
    uuid
  }
`;
export const OrganisationAccountDetailsFragmentDoc = gql`
  fragment OrganisationAccountDetails on OrganisationAccount {
    isAdmin
    account {
      ...AccountDetails
    }
  }
  ${AccountDetailsFragmentDoc}
`;
