import gql from "graphql-tag";

import { boundary, required } from "api/boundary";

export const CONTACT_FIELD_MIN_FRAGMENT = gql`
  fragment ContactFieldMinDetails on ContactField {
    uuid
    fieldName
    fieldDisplay
  }
`;

// context(alexandrchebotar, 2024-05-30): keep in sync with graphqlQueries/number/fragments.ts
//    Added underscore at the end to prevent duplicating fragments
export const NUMBER_MIN_FRAGMENT_ = {
  query: gql`
    fragment NumberMinFragment_ on Number {
      uuid
      vname
      fromAddr
      profilePicture
      numberType
      backendType
    }
  `,
  parse: {
    uuid: required(),
    vname: required(),
    fromAddr: required(),
    numberType: required(),
  },
};

// context(alexandrchebotar, 2024-05-30): keep in sync with graphqlQueries/number/fragments.ts
//    Added underscore at the end to prevent duplicating fragments
export const INITIAL_NUMBER_FRAGMENT_ = {
  query: gql`
    fragment InitialNumber_ on Number {
      ...NumberMinFragment_
      automatorsCount
      numberCode
      signupType
      graphNameStatus
      graphAccountMode
      wabizClientProvisionState
      phoneNumberProvider
      isRoutingEnabled
      numberRoute {
        uuid
        fromAddr
      }
      organisation {
        uuid
        numbers {
          uuid
          vname
          profilePicture
          fromAddr
        }
      }
      subscription {
        state
      }
      waba {
        fbGraphId
        accountReviewStatus
        uuid
        facebookBusiness {
          uuid
          verificationStatus
        }
      }
    }

    ${NUMBER_MIN_FRAGMENT_.query}
  `,
  parse: {
    ...NUMBER_MIN_FRAGMENT_.parse,
    organisation: {
      uuid: required(),
      numbers: boundary({
        uuid: required(),
        fromAddr: required(),
      }),
    },
  },
};
