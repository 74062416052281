import { TOrganisationDetails } from "graphql/organisation";

export const enum ActionType {
  SET_CHAT_UUID = "SET_CHAT_UUID",
  SET_NUMBER_UUID = "SET_NUMBER_UUID",
  SET_UNSAFE_NUMBER_UUID = "SET_UNSAFE_NUMBER_UUID",
  SET_ORGANISATION_UUID = "SET_ORGANISATION_UUID",
  SET_UNSAFE_ORGANISATION_UUID = "SET_UNSAFE_ORGANISATION_UUID",
  SET_VISIBLE_MENU = "SET_VISIBLE_MENU",
  SET_IS_ADMIN = "SET_IS_ADMIN",
  SET_MY_ACCOUNT_UUID = "SET_MY_ACCOUNT_UUID",
  SET_CURRENT_ORGANISATION = "SET_CURRENT_ORGANISATION",
}

export const setNumberUuid = (payload: string) => ({
  type: ActionType.SET_NUMBER_UUID as const,
  payload,
});

export const set_UNSAFE_NumberUuid = (payload?: string) => ({
  type: ActionType.SET_UNSAFE_NUMBER_UUID as const,
  payload,
});

export const setChatUuid = (payload?: string) => ({
  type: ActionType.SET_CHAT_UUID as const,
  payload,
});

export const setOrganisationUuid = (payload: string) => ({
  type: ActionType.SET_ORGANISATION_UUID as const,
  payload,
});

export const set_UNSAFE_OrganisationUuid = (payload?: string) => ({
  type: ActionType.SET_UNSAFE_ORGANISATION_UUID as const,
  payload,
});

export const setVisibleMenu = (payload: boolean) => ({
  type: ActionType.SET_VISIBLE_MENU as const,
  payload,
});

export const setIsAdmin = (payload: boolean) => ({
  type: ActionType.SET_IS_ADMIN as const,
  payload,
});

export const setMyAccountUuid = (payload: string) => ({
  type: ActionType.SET_MY_ACCOUNT_UUID as const,
  payload,
});

export const setCurrentOrganisation = (payload?: TOrganisationDetails) => ({
  type: ActionType.SET_CURRENT_ORGANISATION as const,
  payload,
});

export type Action = ReturnType<
  | typeof setNumberUuid
  | typeof set_UNSAFE_NumberUuid
  | typeof setChatUuid
  | typeof setOrganisationUuid
  | typeof set_UNSAFE_OrganisationUuid
  | typeof setVisibleMenu
  | typeof setIsAdmin
  | typeof setMyAccountUuid
  | typeof setCurrentOrganisation
>;
